.schools-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
  max-width: 1200px;
  margin: 40px auto;
}
.schools-list .card {
  background: #fffde9;
  color: #333;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  position: relative;
  min-height: 120px;
  min-width: 120px;
  text-align: center;
}
.schools-list .card:hover {
  transform: rotate(3deg);
}
.schools-list .card h3 {
  color: #555;
  margin-bottom: 6px;
}
.schools-list .card p {
  color: #999;
  font-size: 0.9em;
}
.schools-list .card div {
  color: #555;
  font-size: 0.7em;
  margin: 20px 0;
  line-height: 1.5em;
}
.schools-list .card a {
  color: #555;
  text-decoration: none;
  display: block;
  background: #e2e2e2;
  font-size: 0.9em;
  width: 100px;
  text-align: center;
  width: 120px;
  padding: 8px;
  border-radius: 4px;
  margin: 20px auto 0;
}
.schools-list-wrap {padding-bottom: 200px; padding-top: 50px;}
.create {
  color: #555;
  max-width: 480px;
  margin: 60px auto;
}
.create label span {
  display: block;
  margin: 30px 0 10px;
}
.create p {
  margin-top: 10px;
  font-size: 0.8em;
}
.create button {
  display: block;
  width: 100px;
  font-size: 1em;
  color: #fff;
  padding: 8px 20px;
  border: 0;
  border-radius: 6px;
  background-color: #036ad5;
  cursor: pointer;
  text-decoration: none;
  margin: 20px auto;
}
.ingredients {
  display: flex;
  align-items: center;
}
.ingredients button {
  margin: 0 0 0 10px;
}

.more-options {border-bottom: 1px solid #f8aa29;border-radius: 15px;margin: 20px 0;padding: 10px;}
.more-options input {width: 20px;display: inline;}
.more-options div{display: inline;}
.more-options div label {font-size: 0.7em; padding-right: 1.2em;}

.hide-all-input {display: none;}
.school-list {display: block;}
.school {
  max-width: 1200px;
  margin: 40px auto;
  text-align: center;
  background: #fff;
  padding: 40px;
  box-sizing: border-box;
}
.school ul {
  display: flex;
  padding: 0;
  justify-content: center;
  margin-top: 0;
}
.school li {
  list-style-type: none;
  margin-right: 10px;
  color: #777;
}
.school li::after {
  content: ",";
}
.school li:last-child::after {
  content: ".";
}
.method {
  text-align: left;
  line-height: 1.5em;
}